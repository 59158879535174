<template>
  <b-form @submit.prevent="submit({ email, password })">
    <b-row>
      <b-col cols="12">
        <transition name="fade">
          <p class="text-center">
            <b-spinner v-if="isLoggingOut" variant="secondary"></b-spinner>
          </p>
          <p class="text-center">
            <b-icon v-if="!isLoggingOut && !logoutError" class="display-1 text-success"
                    icon="check-circle-fill"></b-icon>
          </p>
          <p class="text-center">
            <b-icon v-if="!isLoggingOut && logoutError" class="display-1 text-warning"
                    icon="exclamation-triangle-fill"></b-icon>
          </p>
          <p v-if="!isLoggingOut && logoutError" class="mb-0 text-center">We had some problems logging you out.</p>
        </transition>
      </b-col>
    </b-row>
  </b-form>
</template>

<script>
import {mapActions, mapGetters} from 'vuex'

export default {
  mounted() {
    this.logout().then(() => {
      this.$router.push({name: 'auth.login', query: this.$route.query})
    })
  },
  computed: {
    ...mapGetters('authentication', ['isLoggingOut', 'logoutError'])
  },
  methods: {
    ...mapActions('authentication', ['logout'])
  }
}
</script>

