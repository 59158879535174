<template>
  <b-form @submit.prevent="submit({ email, password })">
    <b-row>
      <b-col cols="12">
        <b-form-group :invalid-feedback="validationInvalidFeedback(loginErrors, 'email')" label="Email Address">
          <b-form-input v-model="email" :disabled="isLoggingIn" :state="validationState(loginErrors, 'email')"
                        class="text-monospace" type="email"></b-form-input>
        </b-form-group>
      </b-col>
      <b-col cols="12">
        <b-form-group :invalid-feedback="validationInvalidFeedback(loginErrors, 'password')" label="Password">
          <b-form-input v-model="password" :disabled="isLoggingIn" :state="validationState(loginErrors, 'password')"
                        class="text-monospace" type="password"></b-form-input>
        </b-form-group>
      </b-col>
      <b-col cols="12" md="3" offset-md="9">
        <b-button block type="submit" variant="secondary">
          <b-spinner v-if="isLoggingIn" small></b-spinner>
          <span v-if="!isLoggingIn">Log In</span></b-button>
      </b-col>
    </b-row>
  </b-form>
</template>

<script>
import validation from '../../mixins/validation'
import {mapActions, mapGetters} from 'vuex'

export default {
  data() {
    return {
      email: '',
      password: ''
    }
  },

  mixins: [validation],
  computed: {
    ...mapGetters('authentication', ['isLoggingIn', 'loginErrors'])
  },
  methods: {
    ...mapActions('authentication', ['login']),
    submit({email, password}) {
      this.login({email, password}).then(response => {
        let path = this.$route.query.next || '/';
        switch (response.data.roles[0].name) {
          case 'User':
            path = '/dashboard';
        }

        this.$router.push({path})
      })
    }
  }
}
</script>

<style>

</style>
